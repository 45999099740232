import React, { lazy, Suspense } from "react";
import Navbar from "./Navbar";
import Home from "./Home";
import "./style.scss";
import Footer from "../Common/Footer";
import { Route, Routes } from "react-router-dom";
import { defaultSettings, SettingsProvider } from "../mui-components/settings";

import { ThemeProvider } from "../theme/theme-provider";
import { MotionLazy } from '../mui-components/animate/motion-lazy';
import AdminBlog from "./Blog/adminBlog";

const Faq = lazy(() => import("../Common/Faq"));
const Blog = lazy(() => import("./Blog"));
const BlogDetails = lazy(() => import("./Blog/blogDetails"));
const Editor = lazy(() => import("./Blog/createBlog"));

const Dashboard = () => {
  return (
    <>
    <SettingsProvider settings={defaultSettings}>
      <ThemeProvider>
        <MotionLazy>
      <Navbar />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/edit/:blogId" element={<Editor />} />
          <Route path="/:title/:blogId" element={<BlogDetails />} />
          <Route path="/:title/admin" element={<BlogDetails />} />
          <Route path="/:title" element={<BlogDetails />} />
          <Route path="/blog/:blogId" element={<BlogDetails />} />
          <Route path="/blog/:blogId/admin" element={<BlogDetails />} />
          <Route path="/admin/blog" element={<AdminBlog />} />
          <Route path="/create/blog" element={<Editor />} />
        </Routes>
      </Suspense>
      <Footer />
        </MotionLazy>
      </ThemeProvider>
    </SettingsProvider>
    </>
  );
};

export default Dashboard;
