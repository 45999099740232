import axios from "axios";

const api_path = "https://api.unicaps.io";

class CommonService {
  SendEmail(params, key) {
    return axios({
      method: "POST",
      url: `${api_path}/api/v1/bkoffice/notification/sendEmail`,
      data: params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        iv: key,
      },
    });
  }
  GetBlogsListing(key) {
    return axios({
      method: "GET",
      url: "https://api.unicaps.io/api/v1/bkoffice/getAllBlogs?pageIndex=0&pageSize=50",
      // data: params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        iv: key,
      },
    });
  }
  GetAllPublished(key) {
    return axios({
      method: "GET",
      url: "https://api.unicaps.io/api/v1/bkoffice/getAllPublished?pageIndex=0&pageSize=50",
      // data: params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        iv: key,
      },
    });
  }
  GetBlogDetails(key, id) {
    return axios({
      method: "GET",
      url: `https://api.unicaps.io/api/v1/bkoffice/getAllBlog/${id}`,
      // data: params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        iv: key,
      },
    });
  }
  CreateBlog(params, key) {
    return axios({
      method: "POST",
      url: "https://api.unicaps.io/api/v1/bkoffice/createBlogs",
      data: params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        iv: key,
      },
    });
  }
  EditBlog(params, key) {
    return axios({
      method: "PUT",
      url: "https://api.unicaps.io/api/v1/bkoffice/editBlog",
      data: params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        iv: key,
      },
    });
  }
  StatusChange(params, key) {
    return axios({
      method: "PUT",
      url: "https://api.unicaps.io/api/v1/bkoffice/statusChange",
      data: params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        iv: key,
      },
    });
  }
}

export const commonService = new CommonService();
