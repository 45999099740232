const formObj = [
  {
    type: "input",
    name: "firstName",
    label: "First Name",
    placeHolder: "First Name",
    wrapperClass: "input-wrapper",
  },
  {
    type: "input",
    name: "lastName",
    label: "Last Name",
    placeHolder: "Last Name",
    wrapperClass: "input-wrapper",
  },
  {
    type: "email",
    name: "email",
    label: "Email",
    placeHolder: "Email",
    wrapperClass: "input-wrapper",
    regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  },
  {
    type: "textarea",
    name: "message",
    label: "Message",
    placeHolder: "Message",
    wrapperClass: "text-area-wrapper",
    maxCount: 500,
  },
];

export default formObj;
