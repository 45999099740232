import { HelmetProvider } from "react-helmet-async";
import "./App.css";
import Dashboard from "./Components";

function App() {
  return (
    <>
      <HelmetProvider>
        <Dashboard />
      </HelmetProvider>
    </>
  );
}

export default App;
