import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { RouterLink } from "../../routes/components";
import { fDate } from "../../utils/format-time";
import { Image } from "../../mui-components/image";
import { Iconify } from "../../mui-components/iconify";
import { usePopover, CustomPopover } from "../../mui-components/custom-popover";
import { maxLine } from "../../theme/styles/mixins";
import { useLocation } from "react-router-dom";

// Utility function to process customLink
function processCustomLink(customLink, id) {
  if (!customLink) {
    return `/${id}`;
  }
  const parts = customLink.split("/");
  if (parts.length > 2) {
    return `/${parts[1]}`;
  }
  return customLink;
}

export function PostItemHorizontal({ post }) {
  const theme = useTheme();
  const popover = usePopover();
  const { title, image, createdAt, body, id } = post;
  const location = useLocation();

  // Process customLink
  const customLink = processCustomLink(post?.customLink, id);

  return (
    <>
      <Link
        style={{ textDecoration: "none" }}
        state={{
          data: post,
          isAdmin: location?.pathname?.includes("admin") ? true : false,
        }}
        component={RouterLink}
        to={
          location?.pathname?.includes("admin")
            ? `${customLink}/admin`
            : customLink
        }
      >
        <Card sx={{ display: "flex", flexDirection: 'column-reverse', }}>
          <Stack spacing={1} sx={{ p: theme.spacing(3, 3, 2, 3) }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              <Box
                component="span"
                sx={{ typography: "caption", color: "text.disabled" }}
              >
                {fDate(createdAt)}
              </Box>
            </Box>

            <Stack spacing={1} flexGrow={1}>
              <Typography
                component={RouterLink}
                state={{ data: post }}
                to={
                  location?.pathname?.includes("admin")
                    ? `${customLink}/admin`
                    : customLink
                }
                color="inherit"
                variant="subtitle2"
                sx={{ ...maxLine({ line: 2 }) }}
              >
                {title}
              </Typography>

              <Typography
                variant="body2"
                sx={{ ...maxLine({ line: 4 }), color: "text.secondary" }}
                dangerouslySetInnerHTML={{ __html: body }}
              />
            </Stack>
          </Stack>

          <Box
            sx={{
              p: 1,
              // width: 100%,
              height: 300,
              flexShrink: 0,
              position: "relative",
              filter: 0,
              ".mnl__image__wrapper": {
                filter: "unset",
              },
              display: { xs: "none", sm: "block" },
            }}
          >
            <Image
              alt={title}
              src={image}
              sx={{ height: 1, borderRadius: 1.5 }}
            />
          </Box>
        </Card>
      </Link>

      <CustomPopover
        open={popover.open}
        anchorEl={popover.anchorEl}
        onClose={popover.onClose}
        slotProps={{ arrow: { placement: "bottom-center" } }}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              popover.onClose();
              // router.push(paths.dashboard.post.details(title));
            }}
            key="view"
          >
            <Iconify icon="solar:eye-bold" />
            View
          </MenuItem>

          <MenuItem
            onClick={() => {
              popover.onClose();
            }}
            key="edit"
          >
            <Iconify icon="solar:pen-bold" />
            Edit
          </MenuItem>
        </MenuList>
      </CustomPopover>
    </>
  );
}
