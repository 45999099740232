const HandleScrollOnClick = (data, navigate, pathname) => {
  if (pathname !== "/") {
    navigate("/");
  }
  if (data?.class) {
    setTimeout(() => {
      const element = document?.getElementsByClassName(data?.class)[0];
      element?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }, 500);
  }
};

export default HandleScrollOnClick;
