import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Modal } from "@mui/material";
import Fade from "@mui/material/Fade";
import "./style.scss";
import Form from "../Form";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TransitionsModal({
  buttonClass,
  buttonName,
  modalWrapperClass = "form-modal-wrapper",
  data,
  resetDataOutside,
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <button className={buttonClass} onClick={handleOpen} type="button">
        {buttonName}
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={modalWrapperClass}
        open={open}
        onClose={handleClose}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Form
              handleClose={handleClose}
              data={data}
              resetDataOutside={resetDataOutside}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default TransitionsModal;
