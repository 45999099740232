import React from "react";
import "./style.scss";
import { ReactComponent as LeftLogo } from "../../Assets/FooterLogo/left.svg";
import { ReactComponent as RightLogo } from "../../Assets/FooterLogo/right.svg";
import ShowList from "./showList";
import { ShowLinksList } from "../../Constants";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="top-wrapper">
        <div className="left w-[30%]">
          <div className="logo-container flex items-center justify-start gap-[12px] mb-[32px]">
            <Link to="/" className="flex items-center gap-[10px]">
              <LeftLogo />
              <RightLogo />
            </Link>
          </div>
          <p className="left-text">
            Top-notch security meets top-tier excellence. Explore innovative
            payment solutions that transform how you provide value to customers
            fast and easy.
          </p>
        </div>
        <div className="right flex items-start justify-between w-[66%]">
          {ShowLinksList?.map((item, index) => {
            return (
              <ShowList
                heading={item?.heading}
                list={item?.list}
                index={index}
                key={`list-${index}`}
              />
            );
          })}
        </div>
      </div>
      <hr className="w-full ruler" />
      <div className="bottom-wrapper flex items-center justify-between">
        <div className="left flex items-center justify-start">
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            <p>Privacy Policy</p>
          </a>
          <a
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noreferrer"
          >
            <p>Terms & Conditions</p>
          </a>
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            <p>Cookie Policy</p>
          </a>
        </div>
        <div className="right">
          <p>
            Copyright © Unicaps {new Date().getFullYear()}. All right reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
