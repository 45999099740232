import { DashboardContent } from '../../../layouts/dashboard';

import { CustomBreadcrumbs } from '../../../mui-components/custom-breadcrumbs';

import { PostNewEditForm } from '../post-new-edit-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import { Iconify } from '../../../mui-components/iconify';

// ----------------------------------------------------------------------

export function PostCreateView({ editor, setEditorContent, handleTitle, title, editorContent, handleEditorChange, onPublishClick, onImageSelect }) {

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <DashboardContent>
      {location?.pathname?.includes('edit') && 
      <Stack direction="row" sx={{ mb: { xs: 3, md: 5 } }}>
       <Button
       style={{ width: '110px', justifyContent: 'flex-start' }}
          onClick={() => navigate(-1)}
          startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
        >
          Back
        </Button>
       </Stack>}
      <CustomBreadcrumbs
        heading={location?.pathname?.includes('edit') ? "Edit Blog" : "Create a new Blog"}
        links={[
          { name: 'Blog', href: '/blog' },
          { name: location?.pathname?.includes('edit') ? 'Edit' : 'Create' },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />

      <PostNewEditForm setEditorContent={setEditorContent} currentPost={location?.state?.data} title={title} editor={editor} handleTitle={handleTitle} onImageSelect={onImageSelect} editorContent={editorContent} handleEditorChange={handleEditorChange} onPublishClick={onPublishClick}/>
    </DashboardContent>
  );
}
