import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TransitionsModal from "../Modal";
import HandleScrollOnClick from "../../CommonUtils";

const ShowList = ({ heading, list, index }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const renderData = (data, listIndex) => {
    if (data?.toLink) {
      return (
        <li className="list-item">
          <Link to={data?.toLink} key={`list-${listIndex}`}>
            {data?.name}
          </Link>
        </li>
      );
    }
    if (data?.link) {
      return (
        <li className="list-item">
          <a
            href={data?.link}
            target="_blank"
            rel="noreferrer"
            key={`list-${listIndex}`}
          >
            {data?.name}
          </a>
        </li>
      );
    }
    if (data?.openModal) {
      return (
        <li className="list-item">
          <TransitionsModal buttonName={data?.name} buttonClass="button" />
        </li>
      );
    }
    if (data?.scrollTo) {
      return (
        <li
          className="list-item cursor-pointer"
          key={`list-${listIndex}`}
          onClick={() => HandleScrollOnClick(data, navigate, pathname)}
        >
          {data?.name}
        </li>
      );
    }
    return (
      <li className="list-item" key={`list-${listIndex}`}>
        {data?.name}
      </li>
    );
  };
  return (
    <div className="showlist-wrapper">
      <div className="heading">{heading}</div>
      <ul className="list-wrapper">
        {list?.map((item, listIndex) => {
          return renderData(item, listIndex);
        })}
      </ul>
    </div>
  );
};

export default ShowList;
