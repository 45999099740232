import React, { Suspense, lazy } from "react";
import "./style.scss";
import Loader from "../../Common/Loader";
import CookieConsent from "react-cookie-consent";
import { ReactComponent as Cookie } from "../../Assets/cookie.svg";

const LazyHomeSection = lazy(() => import("./HomeSection"));
const LazyNumbers = lazy(() => import("../Numbers"));
const LazyAboutUs = lazy(() => import("../AboutUs"));
const LazyWhatWeAre = lazy(() => import("../WhatWeAre"));
const LazyHowWeWork = lazy(() => import("../HowWeWork"));
const LazyIncreaseProductivity = lazy(() => import("../IncreaseProductivity"));
const LazyTestimonials = lazy(() => import("../Testimonials"));
const LazyMailUs = lazy(() => import("../MailsUs"));

const Home = () => {
  return (
    <>
      <Suspense
        fallback={
          <div className="min-h-[100vh] reverse mt-[50vh]">
            <Loader />
          </div>
        }
      >
        <LazyHomeSection />
        <LazyNumbers />
        <LazyAboutUs />
        <LazyWhatWeAre />
        <LazyHowWeWork />
        <LazyIncreaseProductivity />
        <LazyTestimonials />
        <LazyMailUs />
        <CookieConsent
          location="bottom"
          className="cookie-wrapper"
          buttonText="Accept all cookies"
          declineButtonText="Decline all cookies"

          cookieName="myAwesomeCookieName2"
          enableDeclineButton
          expires={150}
          overlay
          flipButtons
        >
          <span className="text-[18px] flex gap-[20px] items-center cookie-content-wrapper">
            {" "}
            <Cookie />
            By clicking “Accept all cookies”, you agree Stack Exchange can store
            cookies on your device and disclose information in accordance with
            our Cookie Policy.{" "}
          </span>
        </CookieConsent>
      </Suspense>
    </>
  );
};

export default Home;
