import { Controller, useFormContext } from 'react-hook-form';

import FormHelperText from '@mui/material/FormHelperText';

import { Upload, UploadBox, UploadAvatar } from '../upload';

// ----------------------------------------------------------------------

export function RHFUploadAvatar({ name, ...other }) {
  const { control, setValue } = useFormContext();

  console.log('in')
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const onDrop = (acceptedFiles) => {
          const value = acceptedFiles[0];

          setValue(name, value, { shouldValidate: true });
        };

        return (
          <div>
            <UploadAvatar value={field.value} error={!!error} onDrop={onDrop} {...other} />

            {!!error && (
              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {error.message}
              </FormHelperText>
            )}
          </div>
        );
      }}
    />
  );
}

// ----------------------------------------------------------------------

export function RHFUploadBox({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <UploadBox value={field.value} error={!!error} {...other} />
      )}
    />
  );
}

// ----------------------------------------------------------------------

export function RHFUpload({ name, multiple, helperText, ...other }) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
    name={name}
    control={control}
    render={({ field, fieldState: { error } }) => {
      const uploadProps = {
        multiple,
        accept: { 'image/*': [] },
        error: !!error,
        helperText: error?.message ?? helperText,
      };

      const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

      const onDrop = async (acceptedFiles) => {
        const file = multiple ? acceptedFiles[0] : acceptedFiles[0];
        try {
          const base64File = await toBase64(file);
          const value = multiple ? [...field.value, base64File] : base64File;

          setValue(name, value, { shouldValidate: true });
        } catch (error) {
          console.error('Error converting file to base64:', error);
        }
      };

      return <Upload {...uploadProps} value={field.value} onDrop={onDrop} {...other} />;
    }}
  />
  );
}
