import { ReactComponent as GooglePayLogo } from "../Assets/HomeSectionLogo/googlepay.svg";
import { ReactComponent as ApplePayLogo } from "../Assets/HomeSectionLogo/applepay.svg";
import { ReactComponent as AmazonPayLogo } from "../Assets/HomeSectionLogo/amazonpay.svg";
import PaytmLogo from "../Assets/HomeSectionLogo/paytm-logo.png";
import BhimLogo from "../Assets/HomeSectionLogo/bhim.png";
import PhonePeLogo from "../Assets/HomeSectionLogo/phonepe-logo.png";
import UpiLogo from "../Assets/HomeSectionLogo/upi.png";

import TestimonialImg from "../Assets/testimonial.png";

export const NavbarItems = [
  { name: "About Us", class: "about-us-wrapper" },
  { name: "Benefit", class: "what-we-are-wrapper" },
  { name: "How it Works", class: "how-we-work-wrapper" },
  { name: "Testimonials", class: "testimonials-wrapper" },
];

export const FloatingLogos = [
  {
    logo: <GooglePayLogo />,
    style: { position: "absolute", top: "-56px", left: "18%" },
  },
  {
    image: PhonePeLogo,
    style: { position: "absolute", top: "150px", left: "-160px" },
    imageStyle: { width: "50%", transform: "rotate(40deg)" },
  },
  {
    image: UpiLogo,
    style: { position: "absolute", bottom: "0", left: "-95px" },
    imageStyle: { width: "50%", transform: "rotate(-30deg)" },
  },
  {
    image: PaytmLogo,
    style: {
      position: "absolute",
      bottom: "-130px",
      left: "34%",
      transform: "translateX(-50%)",
    },
    imageStyle: { width: "50%", transform: "rotate(15deg)" },
  },
  {
    image: BhimLogo,
    style: { position: "absolute", bottom: "-70px", left: "56%" },
    imageStyle: { width: "50%", transform: "rotate(30deg)" },
  },
  {
    logo: <AmazonPayLogo />,
    style: {
      position: "absolute",
      bottom: "130px",
      right: "-125px",
      logoStyle: { width: "170px" },
    },
  },
  {
    logo: <ApplePayLogo />,
    style: { position: "absolute", top: "0", right: "-35px" },
  },
];

export const FloatingLogosMobile = [
  {
    logo: <GooglePayLogo />,
    style: { position: "absolute", top: "-25%", left: "10%" },
  },
  {
    image: PhonePeLogo,
    style: { position: "absolute", top: "50%", left: "-17%" },
    imageStyle: { width: "50%", transform: "rotate(40deg)" },
  },
  {
    image: PaytmLogo,
    style: {
      position: "absolute",
      bottom: "-20%",
      left: "34%",
      transform: "translateX(-50%)",
    },
    imageStyle: { width: "50%", transform: "rotate(15deg)" },
  },
  {
    image: BhimLogo,
    style: { position: "absolute", bottom: "-70px", left: "56%" },
    imageStyle: { width: "50%", transform: "rotate(30deg)" },
  },
  {
    logo: <AmazonPayLogo />,
    style: {
      position: "absolute",
      bottom: "-10%",
      right: "-30%",
      logoStyle: { width: "170px" },
    },
  },
  {
    logo: <ApplePayLogo />,
    style: { position: "absolute", top: "-5%", right: "-35px" },
  },
];

export const NumbersConstants = [
  {
    number: "52K",
    description: "Active Customers",
  },
  {
    number: "99.9%",
    description: "User satisfaction",
    hr: true,
  },
  {
    number: "3.2 m",
    description: "Successful transaction ",
  },
];

export const ABOUT_DESCRIPTION =
  "Unicaps, your ultimate solution for seamless and secure financial transactions in the digital age. Revolutionizing the way you handle payments, our app combines cutting-edge technology with user-friendly design to provide you with a hassle-free and efficient payment experience.";

export const WHAT_ARE_WE_Decription =
  "Our platform helps you managing your transactions. These are some of the reasons why you should use our platform in managing your business expences.";

export const WHAT_WE_ARE_CARDS = [
  {
    label: "Easy To Use Dashboard",
    description:
      "Navigate your payment processes effortlessly with Unicaps' intuitive dashboard. Our user-friendly interface streamlines your workflow, providing a seamless experience for managing transactions and gaining insights at your fingertips.",
  },
  // {
  //   label: "Custom -Check Out",
  //   description:
  //     "Elevate your brand with Unicaps' Custom Checkout feature. Tailor the checkout experience to match your brand identity, ensuring a cohesive and memorable journey for your customers. Reinforce trust and recognition during the critical point of purchase.",
  // },
  {
    label: "Realtime Tracking",
    description:
      "Stay informed with real-time tracking on Unicaps. Monitor transactions, analyze trends, and make data-driven decisions instantly. Our platform empowers you with up-to-the-minute insights, allowing you to adapt strategies and stay ahead of the curve.",
  },
  {
    label: "24/7 Customers support",
    description:
      "Experience unwavering support with Unicaps' round-the-clock customer service. Our dedicated team is ready to assist you at any hour, ensuring that your queries are addressed promptly and your payment processes run smoothly.",
  },
  {
    label: "Multiple Payments options",
    description:
      "Cater to diverse customer preferences with Unicaps' support for multiple payment options. From credit cards to digital wallets, our platform seamlessly integrates a variety of payment methods, enhancing flexibility and accommodating a broader customer base.",
  },
  {
    label: "128 bit Encryption Security",
    description:
      "Rest assured with Unicaps' top-tier security measures. Our 128-bit encryption ensures that sensitive data is protected at the highest standard. Prioritize the security of your transactions and customer information with our robust security infrastructure.",
  },
];

export const HOW_WE_WORK_DESCRIPTION =
  "Experience seamless transactions with Unicaps in just a few easy steps. Choose Unicaps as your payment option, select your preferred method, and follow the simple prompts. Whether it's UPI or another method, Unicaps streamlines the process for quick and hassle-free payments. Try Unicaps today – where simplicity meets efficiency!";

export const HOW_WE_WORK_STEPS = [
  {
    description: "Register your account.",
    class: "w-[124px] left-[110px]",
  },
  {
    description: "Perform your payment transaction",
    class: "w-[192px] left-[49%]",
  },
  {
    description: "Done, let’s continue the work.",
    class: "w-[175px] right-[85px]",
  },
];

export const INCREASE_PRODUCTIVITY = {
  subHeading:
    "Get a quick snapshot of your account status, recent activities, and important notifications as soon as you log in",
  description: [
    "Review a detailed history of all your transactions, including payments, withdrawals, and deposits. Filter and search options make it easy to find specific transactions.",
    "Monitor your account balance at a glance. The balance summary provides a clear breakdown of available funds, pending transactions, and any outstanding payments.",
    "Manage your preferred payment methods from one central location. Add, remove, or edit payment options seamlessly to tailor your experience.",
    "Initiate settlement requests effortlessly. Track the progress of settlements and receive real-time updates on their status.",
  ],
};

export const TESTIMONIAL_CARDS = [
  {
    heading: "⭐⭐⭐⭐⭐",
    description:
      "Unicaps has truly transformed the way we handle payments. The easy-to-use dashboard simplifies our workflow, making transaction management a breeze. The custom checkout feature has added a touch of professionalism to our brand, and the real-time tracking ensures we're always in the know. Kudos to Unicaps for their excellent service and top-notch security!",
    image: TestimonialImg,
    imageHeading: "Jimmy Bartney",
    imageSubHeading: "Product Manager at XYZ",
  },
  {
    heading: "⭐⭐⭐⭐⭐",
    description:
      "As a business owner, having 24/7 customer support is invaluable, and Unicaps delivers on that front. The team is responsive, knowledgeable, and goes above and beyond to assist. Multiple payment options have broadened our customer base, and the 128-bit encryption security gives us peace of mind. Unicaps is a game-changer in the payment gateway arena!",
    image: TestimonialImg,
    imageHeading: "Jimmy Bartney",
    imageSubHeading: "Product Manager at XYZ",
  },
  {
    heading: "⭐⭐⭐⭐⭐",
    description:
      "Unicaps has made our payment processing a joy. The custom checkout feature allows us to maintain a consistent brand image, while the real-time tracking keeps us ahead of market trends. The 24/7 customer support is a lifesaver, and the multiple payment options accommodate our diverse customer base. Unicaps is our go-to for secure and seamless transactions.",
    image: TestimonialImg,
    imageHeading: "Jimmy Bartney",
    imageSubHeading: "Product Manager at XYZ",
  },
  {
    heading: "⭐⭐⭐⭐⭐",
    description:
      "Unicaps has exceeded our expectations in every way. The easy-to-use dashboard is a time-saver, and the custom checkout feature has given our brand a unique identity. Real-time tracking provides valuable insights, and the 24/7 customer support is consistently reliable. With Unicaps, we've experienced a significant boost in efficiency and customer satisfaction.",
    image: TestimonialImg,
    imageHeading: "Jimmy Bartney",
    imageSubHeading: "Product Manager at XYZ",
  },
  {
    heading: "⭐⭐⭐⭐⭐",
    description:
      "Choosing Unicaps for our payment gateway was a wise decision. The multiple payment options have made transactions seamless for our customers, and the 128-bit encryption security ensures their data is always protected. The easy-to-use dashboard and real-time tracking features have become indispensable tools in our day-to-day operations. Unicaps has truly elevated our business.",
    image: TestimonialImg,
    imageHeading: "Jimmy Bartney",
    imageSubHeading: "Product Manager at XYZ",
  },
];

export const ShowLinksList = [
  {
    heading: "Quick links",
    list: [
      { name: "Get free demo", logo: "", openModal: true },
      {
        name: "How we work?",
        logo: "",
        class: "how-we-work-wrapper",
        scrollTo: true,
      },
      {
        name: "Features",
        logo: "",
        class: "what-we-are-wrapper",
        scrollTo: true,
      },
    ],
  },
  {
    heading: "Company",
    list: [
      {
        name: "About Us",
        logo: "",
        class: "about-us-wrapper",
        scrollTo: true,
      },
      { name: "Contact Us", logo: "", openModal: true },
      {
        name: "Conect with Telegram",
        logo: "",
        link: "https://t.me/oliverthomas112",
      },
    ],
  },
  {
    heading: "Resources",
    list: [
      { name: "Blog", logo: "", toLink: "/blog" },
      { name: "FAQ", logo: "", toLink: "/faq" },
    ],
  },
  {
    heading: "Follow Us",
    list: [
      {
        name: "LinkedIn",
        logo: "",
        link: "https://www.linkedin.com/company/unicaps-io/about/?viewAsMember=true",
      },
      {
        name: "Instagram",
        logo: "",
        link: "https://www.instagram.com/unicaps.io",
      },
      {
        name: "Facebook",
        logo: "",
        link: "https://www.facebook.com/unicaps.io",
      },
      {
        name: "YouTube",
        logo: "",
        link: "https://www.youtube.com/@UnicapsSolutions",
      },
    ],
  },
];

export const FaqPoints = [
  {
    q: "What is Unicaps?",
    a: "Unicaps is a versatile online payment platform that facilitates secure and seamless transactions, offering various payment methods to cater to your preferences.",
  },
  {
    q: "How do I sign up for Unicaps?",
    a: "Signing up for Unicaps is easy! Visit our website and follow the simple registration process. Provide the required information, verify your identity, and you're ready to go. Or you can use email - onboard@Unicaps.io",
  },
  {
    q: "What payment methods does Unicaps support?",
    a: "Unicaps supports a range of payment methods, including UPI, IMPS, and more. Choose the method that suits you best during the checkout process.",
  },
  {
    q: "Can I track my transaction history on Unicaps?",
    a: "Absolutely! Unicaps provides a comprehensive transaction history feature, allowing you to view and track all your past transactions easily.",
  },
  {
    q: "Is my financial information safe with Unicaps?",
    a: "Yes, Unicaps prioritizes the security of your financial information. We employ robust encryption and security measures to ensure that your data is protected.",
  },
  {
    q: "How do settlement requests work on Unicaps?",
    a: "Unicaps offers T+0 settlement, meaning you can request settlements as your pay-in amount reaches specific milestones. This provides flexibility and control over your transactions.",
  },
  {
    q: "Can I manage multiple payment methods on Unicaps?",
    a: "Yes, Unicaps allows you to manage and customize your preferred payment methods. Add, remove, or edit payment options easily within your account settings.",
  },
  {
    q: "How do I contact Unicaps support?",
    a: "For any inquiries or assistance, visit our Support and Help Center on the dashboard. You can find FAQs, guides, and contact options to connect with our support team.",
  },
  {
    q: "Does Unicaps offer a newsletter or updates?",
    a: "Yes, Unicaps provides a newsletter! Subscribe to stay informed about the latest features, promotions, and industry news. Simply enter your email address in the subscribe section on our website.",
  },
  {
    q: "Can I unsubscribe from Unicaps communications?",
    a: 'Yes, you have the option to unsubscribe from our communications at any time. Click on the "unsubscribe" link in our emails, or manage your preferences in your account settings.',
  },
];
