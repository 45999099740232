import { RHFCode } from './rhf-code';
import { RHFTextField } from './rhf-text-field';
import { RHFSwitch } from './rhf-switch';
import { RHFSelect } from './rhf-select';
import { RHFAutocomplete } from './rhf-autocomplete';
import { RHFUpload, RHFUploadBox, RHFUploadAvatar } from './rhf-upload';

// ----------------------------------------------------------------------

export const Field = {
  Code: RHFCode,
  Select: RHFSelect,
  Upload: RHFUpload,
  Switch: RHFSwitch,
  Text: RHFTextField,
  UploadBox: RHFUploadBox,
  Autocomplete: RHFAutocomplete,
  UploadAvatar: RHFUploadAvatar,
};
