import React, { useState } from "react";
import { NavbarItems } from "../../Constants";
import { ReactComponent as Logo } from "../../Assets/Logo/logo.svg";
import { ReactComponent as LogoText } from "../../Assets/Logo/logo-text.svg";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import TransitionsModal from "../../Common/Modal";
import HandleScrollOnClick from "../../CommonUtils";

const Navbar = () => {
  const [active, setActive] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <div className="navbar-section">
        <Link to="/" aria-label="home-on-logo-click">
          <div className="flex items-center gap-[12px] logo-wrapper">
            <Logo />
            <LogoText />
          </div>
        </Link>
        <div
          className={`flex items-center justify-between	gap-[40px] menu ${
            active ? "active" : ""
          }`}
        >
          {NavbarItems?.map((item) => {
            return (
              <span
                className="menu-items"
                aria-hidden
                onClick={() => HandleScrollOnClick(item, navigate, pathname)}
                key={item?.name}
              >
                {item?.name || ""}
              </span>
            );
          })}
          <TransitionsModal buttonName="Contact Us" buttonClass="contact_us" />
        </div>
        <div className="desktop">
          <TransitionsModal
            buttonName="Contact Us"
            buttonClass="contact_us primary-btn"
          />
        </div>
        <span className={`drawer-open mobile ${active ? "active" : ""}`}>
          <span aria-hidden onClick={() => setActive(!active)}></span>
        </span>
      </div>
    </>
  );
};

export default Navbar;
