import React, { useEffect, useState } from "react";
import "./style.scss";
import { commonService } from "../../CommonService";
import CryptoJS from "crypto-js";
import { PostListView } from "../new-blog/view";

const AdminBlog = () => {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const iv = CryptoJS.enc.Latin1.parse(
    `${process.env.REACT_APP_IV}` || "0123456789abcdef"
  );
  const key = CryptoJS.enc.Latin1.parse(
    `${process.env.REACT_APP_KEY}` || "DU16HY06MP985458"
  );
  const getHeaderKey = () => {
    return CryptoJS.AES.encrypt("unicaps#9876~uniblog@123#", key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
    });
  };

  const loadHandler = async () => {
    const key = getHeaderKey();
    try {
      const res = await commonService.GetBlogsListing(key);

      setBlogs(res?.data?.data || []);
      setIsLoading(false);
    } catch (e) {}
  };

  useEffect(() => {
    loadHandler();
  }, [JSON.stringify(loadHandler)]);

  return (
    <div className="min-h-[100vh] mt-[80px] pt-[100px] blog-wrapper">
      <PostListView isAdmin={true} isLoading={isLoading} blogList={blogs?.bloglist} />
    </div>
  );
};

export default AdminBlog;
