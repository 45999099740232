import { useState, useEffect, useCallback, Suspense } from 'react';

import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { DashboardContent } from '../../../layouts/dashboard';

import { Markdown } from '../../../mui-components/markdown';

import { PostDetailsHero } from '../post-details-hero';
import CryptoJS from "crypto-js";
import { PostDetailsToolbar } from '../post-details-toolbar';
import { useLocation, useParams } from 'react-router-dom';
import { commonService } from '../../../CommonService';
import Loader from '../../../Common/Loader';
import { Helmet } from 'react-helmet-async';

// ----------------------------------------------------------------------

export const POST_PUBLISH_OPTIONS = [
  { value: 'published', label: 'Published' },
  { value: 'unpublished', label: 'Unpublished' },
];

export function PostDetailsView() {
  const [publish, setPublish] = useState('');
  const [post, setPost] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const location = useLocation()
  const { title } = useParams();
  const iv = CryptoJS.enc.Latin1.parse(
    `${process.env.REACT_APP_IV}` || "0123456789abcdef"
  );
  const key = CryptoJS.enc.Latin1.parse(
    `${process.env.REACT_APP_KEY}` || "DU16HY06MP985458"
  );

  const getHeaderKey = () => {
    return CryptoJS.AES.encrypt("unicaps#9876~uniblog@123#", key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
    });
  };
  

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const onStatusChange = async (newValue) => {
    const payload = {
      id: location?.state?.data?.id,
      title: location?.state?.data?.title,
      body: location?.state?.data?.body,
      image: location?.state?.data?.image,
      blogStatus: newValue === 'published' ? 'PUBLISHED' : 'NOT_PUBLISHED'
    };
    const key = getHeaderKey();
    try {
      await commonService.StatusChange(payload, key);
      setSnackbar({ open: true, message: 'Status changed successfully', severity: 'success' });
    } catch (e) {
      console.log("An Error occured", e);
      setSnackbar({ open: true, message: 'An error occurred', severity: 'error' });
    }
  };

  const handleChangePublish = useCallback((newValue) => {
    setPublish(newValue);
    onStatusChange(newValue)
  }, [onStatusChange]);

  const loadHandler = async () => {
    const key = getHeaderKey();
    try {
      const res = await commonService.GetBlogDetails(key, title);
      setPost(res?.data?.data || []);
      setLoading(false);
    } catch (e) {
      setSnackbar({ open: true, message: 'Failed to load post details', severity: 'error' });
    }
  };

  const loadHandlerDep = JSON.stringify(loadHandler);

  useEffect(() => {
    loadHandler();
  }, [loadHandlerDep]);

  useEffect(() => {
    if (post) {
      setPublish(post?.status === "PUBLISHED" ? 'published' : 'unpublished');
    }
  }, [post, location?.state]);

  console.log(post, 'locs')
  

  return (
    <>
     {loading ? <div className="min-h-[100vh] reverse mt-[30vh]">
            <Loader />
          </div> :
    <DashboardContent maxWidth={false} disablePadding>
      <Suspense fallback={<Loader />}>
      <Helmet>
        <title>{post?.metaTitle}</title>
        <meta name="description" content={post?.description} />
        <meta name="keywords" content={post?.keywords?.join(', ')} />
        <meta name="robots" content="index,follow" />
        <meta property="og:title" content={post?.metaTitle} />
        <meta property="og:description" content={post?.description} />
        <meta property="og:image" content={post?.image} />
        <meta property="og:url" content={`https://www.unicaps.io${post?.customLink}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post?.metaTitle} />
        <meta name="twitter:description" content={post?.description} />
        <meta name="twitter:image" content={post?.image} />
      </Helmet>
      <Container maxWidth={false} sx={{ px: { sm: 5 }, mt: 3 }}>
        <PostDetailsToolbar
          backLink={location?.pathname?.includes('admin') ? '/admin/blog' : '/blog'}
          detailData={post}
          isAdmin={location?.state?.isAdmin}
          editLink={`/blog/edit/${location?.state?.data?.id}`}
          publish={`${publish}`}
          onChangePublish={handleChangePublish}
          publishOptions={POST_PUBLISH_OPTIONS}
        />
      </Container>

      <PostDetailsHero title={`${post?.title}`} coverUrl={`${post?.image}`} />

      <Stack
        sx={{
          pb: 5,
          mx: 'auto',
          maxWidth: 720,
          mt: { xs: 5, md: 10 },
          px: { xs: 2, sm: 3 },
        }}
      >
        <Typography variant="subtitle1">{post?.description}</Typography>

        <Markdown children={post?.body} />

      </Stack>
      </Suspense>
    </DashboardContent>}
    <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
