import packageJson from '../package.json';

// ----------------------------------------------------------------------

export const CONFIG = {
  site: {
    name: 'Minimals',
    serverUrl: '',
    assetURL: '',
    basePath: '',
    version: packageJson.version,
  },
  /**
   * Auth
   * @method jwt | amplify | firebase | supabase | auth0
   */
  auth: {
    method: 'jwt',
    skip: false,
  },
  /**
   * Mapbox
   */
 
  /**
   * Firebase
   */
  /**
   * Amplify
   */
  /**
   * Auth0
   */
  /**
   * Supabase
   */
};
