import React, { useEffect, useState } from "react";
import "./style.scss";
import formObj from "./config";
import { TextField, TextareaAutosize } from "@mui/material";
import CryptoJS from "crypto-js";
import { commonService } from "../../CommonService";

const Form = ({ handleClose, data = {}, resetDataOutside = null }) => {
  const [formValues, setFormValues] = useState({});
  const iv = CryptoJS.enc.Latin1.parse(
    `${process.env.REACT_APP_IV}` || "0123456789abcdef"
  );
  const key = CryptoJS.enc.Latin1.parse(
    `${process.env.REACT_APP_KEY}` || "DU16HY06MP985458"
  );

  useEffect(() => {
    formObj?.forEach((item) => {
      if (
        Object.keys(data)?.length &&
        Object.keys(data)?.includes(item?.name)
      ) {
        setFormValues((formValues) => ({
          ...formValues,
          [item?.name]: { value: data?.[item?.name], touched: true },
        }));
      }
    });
  }, [data]);

  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setFormValues((formValues) => ({
      ...formValues,
      [name]: { value, touched: true },
    }));
  };

  const createPayload = () => {
    return {
      emailTo: formValues["email"]?.value,
      subject: `Query raised by ${formValues["firstName"]?.value} ${formValues["lastName"]?.value}`,
      body: formValues["message"]?.value,
    };
  };

  const getHeaderKey = (txnID) => {
    return CryptoJS.AES.encrypt(`unicaps#9876~${txnID}`, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const key = getHeaderKey(formValues["email"]?.value);
    const payload = createPayload(formValues);
    try {
      await commonService.SendEmail(payload, key);
    } catch (e) {
      console.log("error occured", e);
    } finally {
      handleClose();
      if (resetDataOutside) {
        resetDataOutside();
      }
    }
  };

  const renderType = (item) => {
    switch (item?.type) {
      case "input":
        return (
          <>
            <TextField
              variant="outlined"
              className={item?.className || "input"}
              label={item?.placeHolder}
              id={`${item?.name} outlined-basic`}
              value={formValues[item?.name]?.value}
              name={item?.name}
              onChange={handleChange}
            />
          </>
        );
      case "email":
        return (
          <TextField
            variant="outlined"
            className={item?.className || "input"}
            label={item?.placeHolder}
            id={`${item?.name} outlined-basic`}
            value={formValues[item?.name]?.value}
            name={item?.name}
            onChange={handleChange}
          />
        );
      case "textarea":
        return (
          <>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder={item?.placeHolder}
              id={item?.name}
              value={formValues[item?.value]}
              name={item?.name}
              onChange={handleChange}
            />
            <div>
              <p className=""></p>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const checkIfDisabled = () => {
    let isDisabled = false;

    formObj?.forEach((item) => {
      if (Object.keys(formValues)?.includes(item?.name)) {
        if (item?.regex) {
          const isFalse = item?.regex.test(formValues[item?.name].value);
          isDisabled = !isFalse;
        }
      } else {
        isDisabled = true;
      }
    });

    return isDisabled;
  };

  return (
    <div className="form-wrapper">
      <h1 className="mb-[10px] heading">Speak to Us</h1>
      <p className="mb-[20px] description">
        Let's connect. Whatever your payments challenge, get in contact with a
        member of our team to see how we can help.
      </p>
      <div className="contact_us text-center">
        <a href="https://t.me/oliverthomas112" target="_blank" rel="noreferrer">
          <button className=" primary-btn">Connect with Telegram</button>
        </a>
      </div>
      <div className="ruler" />
      <form onSubmit={onSubmit}>
        {formObj?.map((item) => {
          return <div className={item?.wrapperClass}>{renderType(item)}</div>;
        })}
        <div className="flex items-center w-full justify-center">
          <button
            type="submit"
            className={`primary-btn mt-[20px] ${
              checkIfDisabled() ? "disabled" : ""
            }`}
            disabled={checkIfDisabled()}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form;
