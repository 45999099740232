import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';

import { RouterLink } from '../../routes/components';

import { Iconify } from '../../mui-components/iconify';
import { usePopover, CustomPopover } from '../../mui-components/custom-popover';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export function PostDetailsToolbar({
  publish,
  backLink,
  editLink,
  liveLink,
  detailData,
  publishOptions,
  onChangePublish,
  sx,
  ...other
}) {
  const popover = usePopover();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Stack spacing={1.5} direction="row" sx={{ mb: { xs: 3, md: 5 }, ...sx }} {...other}>
        <Button
          component={RouterLink}
          onClick={() => navigate(-1)}
          startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
        >
          Back
        </Button>

        <Box sx={{ flexGrow: 1 }} />

        {location?.pathname?.includes('admin') && <Tooltip title="Edit">
         <Link to={editLink} state={{ data: detailData }}>
         <IconButton>
            <Iconify icon="solar:pen-bold" />
          </IconButton>
         </Link>
        </Tooltip>}

       {location?.pathname?.includes('admin') && <LoadingButton
          color="inherit"
          variant="contained"
          loading={!publish}
          loadingIndicator="Loading…"
          endIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}
          onClick={popover.onOpen}
          sx={{ textTransform: 'capitalize' }}
        >
          {publish}
        </LoadingButton>}
      </Stack>

      <CustomPopover
        open={popover.open}
        anchorEl={popover.anchorEl}
        onClose={popover.onClose}
        slotProps={{ arrow: { placement: 'top-right' } }}
      >
        <MenuList>
          {publishOptions.map((option) => (
            <MenuItem
              key={option.value}
              // selected={option.value === publish}
              onClick={() => {
                popover.onClose();
                onChangePublish(option.value);
              }}
            >
              {/* {option.value === 'published' && <Iconify icon="eva:cloud-upload-fill" />} */}
              {/* {option.value === 'unpublished' && <Iconify icon="solar:file-text-bold" />} */}
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </CustomPopover>
    </>
  );
}
