import { useState, useCallback, useEffect } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Button from '@mui/material/Button';

import { useSetState } from '../../../Common/hooks/use-set-state';

import { DashboardContent } from '../../../layouts/dashboard';

import { Label } from '../../../mui-components/label';
import { Iconify } from '../../../mui-components/iconify';
import { CustomBreadcrumbs } from '../../../mui-components/custom-breadcrumbs';

import { PostListHorizontal } from '../post-list-horizontal';
import Loader from '../../../Common/Loader';

// ----------------------------------------------------------------------

export function PostListView({ blogList, isLoading, isAdmin }) {

  const userTab = ['all'];
  const adminTab = ['all', 'published', 'unpublished'];

  const [userTabs, setUserTabs] = useState(userTab);

  const filters = useSetState({ publish: 'all' });

  useEffect(() => {
    if(isAdmin) {
      setUserTabs(adminTab)
    } else {
      setUserTabs(userTab);
    }
  },[isAdmin])

  const handleFilterPublish = useCallback(
    (event, newValue) => {
      filters.setState({ publish: newValue });
    },
    [filters]
  );

  return (
    <>
       {isLoading ? 
          <div className="min-h-[100vh] reverse mt-[30vh]">
            <Loader />
          </div>
        : <DashboardContent>
      <CustomBreadcrumbs
        heading="Blogs"
        links={[
          { name: 'Resources' },
          { name: 'Blog' },
        ]}
        action={
        <>
          {
            isAdmin ?  <Button
              href={'/create/blog'}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              Add New Blog
            </Button> : <></>
          }
        </>
        }
        sx={{ mb: { xs: 3, md: 5 } }}
      />
      <Tabs
        value={filters.state.publish}
        onChange={handleFilterPublish}
        sx={{ mb: { xs: 3, md: 5 } }}
      >
        {userTabs.map((tab) => (
          <Tab
            key={tab}
            iconPosition="end"
            value={tab}
            label={tab}
            icon={
              <Label
                variant={((tab === 'all' || tab === filters.state.publish) && 'filled') || 'soft'}
                color={(tab === 'published' && 'info') || 'default'}
              >
                {tab === 'all' && blogList?.length}

                {tab === 'published' && blogList?.filter((post) => post?.status === 'PUBLISHED').length}

                {tab === 'unpublished' && blogList?.filter((post) => post?.status === 'NOT_PUBLISHED').length}
              </Label>
             }
            sx={{ textTransform: 'capitalize' }}
          />
        ))}
      </Tabs>
      <PostListHorizontal isAdmin={isAdmin} blogList={(filters?.state?.publish === 'all') ? blogList : filters?.state?.publish === 'published' ? blogList.filter(blog => blog?.status === 'PUBLISHED') : filters?.state?.publish === 'unpublished' ? blogList.filter(blog => blog?.status === 'NOT_PUBLISHED') : []} />
    </DashboardContent>}
    </>
  );
}
